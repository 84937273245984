// app style 
@import "../../App.scss";

nav{
    width: 100%;
    height: 80px;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    box-shadow: 0 0 15px rgba(5, 4, 4, 0.11);
    @media(max-width:768px){
        height: 60px;
    }
    .navLogo{
        height: 80px;
        float: left;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media(max-width:768px){
            height: 60px;
        }
        img{
            width: 75%;
            height: 75%;
            object-fit: contain;
            @media(max-width:480px){
                height: 75%; 
                width: 75%; 
            }
        }
    }
    .navLink{
        float: right;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media(max-width:1024px){
            display: none;
        }
        ul{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            li{
                list-style: none;
                float: left;
                a{
                    text-decoration: none;
                    font-size: 14px;
                    display: flex;
                    margin-left: 50px;
                    color: rgb(110, 109, 109);
                    &:hover{
                        color: $primaryColor;
                    }
                }
                .navLinkActive{
                    color: $primaryColor;
                    font-weight: 500;
                }
                .navContact{
                    background: $primaryColor;
                    color: white;
                    padding: 12px 35px;
                    border-radius: 15px;
                    font-weight: 300;
                    margin-left: 80px;
                    &:hover{
                        background: $secondaryColor;
                    }
                }
            }
        }
    }
    .navBar{
        display: none;
        height: 80px;
        float: right;
        justify-content: center;
        align-items: center;
        @media(max-width:1024px){
            display: flex;
        }
        @media(max-width:768px){
            height: 60px;
        }
        .navBarBox{
            cursor: pointer;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span{
                width: 100%;
                height: 2px;
                background: $secondaryColor;
                margin-top: 3px;
                &:nth-child(1){
                    margin-top: 0;
                }
            }
        }
    }

    
}

.sidemenu{
    background-color: white;
    position: fixed;
    transition: 0.3s;
    top: 0;
    right: -100%;
    z-index: 110;
    height: 100vh;
    width: 100%;
    display: block;
    

    .sidemenuClose{
        float: right;
        padding: 20px;
        i{
            font-size: 30px;
            color: $secondaryColor;
        }
    }

    .burgerMenu{
        display: grid;
        align-items: center;
        margin-top: 50px;
        li{
            list-style: none;
            padding: 10px 0;
            a{
                text-decoration: none;
                padding: 20px;
                color: black;
                font-size: 18px;
                &:hover{
                    color: $primaryColor;
                }
            }
        }
    }
}

.sidemenuActive{
    right: 0;
    transition: 0.3s;
}