@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  font-family: 'Rubik', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #002147;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7482ff;
}

.container {
  width: 90%;
  margin: 0 auto;
}

a, button, div, span, i, b, h1, h2, h3, h4, h5, h6, p, input, ul, li, select, label, textarea {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Rubik', sans-serif;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

input {
  -webkit-tap-highlight-color: transparent;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

textarea {
  resize: none;
  font-family: 'Rubik', sans-serif;
}

main {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#banner {
  width: 100%;
  margin-top: 80px;
}

@media (max-width: 768px) {
  #banner {
    margin-top: 60px;
  }
}

#banner .banerMain {
  width: 100%;
  height: calc(100vh - 80px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media (max-width: 1024px) {
  #banner .banerMain {
    height: auto;
  }
}

#banner .banerMain .banerContent {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 50px 5%;
}

@media (max-width: 1024px) {
  #banner .banerMain .banerContent {
    width: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    padding: 100px 5%;
  }
}

@media (max-width: 768px) {
  #banner .banerMain .banerContent {
    padding: 50px 5%;
  }
}

#banner .banerMain .banerContent h1 {
  font-size: 3.5em;
  font-weight: 600;
}

@media (max-width: 768px) {
  #banner .banerMain .banerContent h1 {
    font-size: 3em;
  }
}

@media (max-width: 480px) {
  #banner .banerMain .banerContent h1 {
    font-size: 2.5em;
  }
}

#banner .banerMain .banerContent h1 span {
  color: #7482ff;
}

#banner .banerMain .banerContent p {
  font-size: 20px;
  margin-top: 30px;
  line-height: 1.7;
  width: 60%;
  opacity: 0.7;
}

@media (max-width: 768px) {
  #banner .banerMain .banerContent p {
    width: 80%;
  }
}

@media (max-width: 480px) {
  #banner .banerMain .banerContent p {
    width: 100%;
  }
}

#banner .banerMain .banerContent a {
  text-decoration: none;
  width: 200px;
  background: #7482ff;
  color: white;
  font-size: 18px;
  margin-top: 40px;
  text-align: center;
  padding: 17px;
  border-radius: 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

#banner .banerMain .banerContent a:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: #002147;
}

#banner .banerMain .banerThumbnail {
  width: 50%;
  height: 100%;
  background: #7482ff;
  padding: 0px 5%;
  position: relative;
  z-index: 0;
}

@media (max-width: 1024px) {
  #banner .banerMain .banerThumbnail {
    width: 100%;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    height: 600px;
  }
}

@media (max-width: 480px) {
  #banner .banerMain .banerThumbnail {
    height: 350px;
  }
}

#banner .banerMain .banerThumbnail .banerThumbnailBox {
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
}

#banner .banerMain .banerThumbnail .banerThumbnailBox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#banner .banerMain .banerThumbnail .bannerElement1 {
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #afb6f5f5;
  opacity: 0.2;
  top: 10%;
  z-index: -1;
  -webkit-animation: flashUp 1.5s;
          animation: flashUp 1.5s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes flashUp {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}

@keyframes flashUp {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}

#banner .banerMain .banerThumbnail .bannerElement2 {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #afb6f5f5;
  opacity: 0.2;
  bottom: 15%;
  right: 18%;
  z-index: -1;
  -webkit-animation: flashUp2 1.8s;
          animation: flashUp2 1.8s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes flashUp2 {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}

@keyframes flashUp2 {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}

#banner .banerMain .banerThumbnail .bannerElement3 {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #afb6f5f5;
  opacity: 0.2;
  top: 25%;
  right: 28%;
  z-index: -1;
  -webkit-animation: flashUp3 1.3s;
          animation: flashUp3 1.3s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes flashUp3 {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}

@keyframes flashUp3 {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}

#banner .banerMain .banerThumbnail .bannerElement4 {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #afb6f5f5;
  opacity: 0.2;
  bottom: 25%;
  left: 20%;
  z-index: -1;
  -webkit-animation: flashUp4 1s;
          animation: flashUp4 1s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes flashUp4 {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}

@keyframes flashUp4 {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}

#banner .banerMain .banerThumbnail .bannerElementBox1 {
  position: absolute;
  padding: 15px;
  background: white;
  width: 300px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  left: -100px;
  bottom: 35%;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.151);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.151);
}

@media (max-width: 1024px) {
  #banner .banerMain .banerThumbnail .bannerElementBox1 {
    left: 0px;
  }
}

@media (max-width: 768px) {
  #banner .banerMain .banerThumbnail .bannerElementBox1 {
    left: 20px;
  }
}

@media (max-width: 480px) {
  #banner .banerMain .banerThumbnail .bannerElementBox1 {
    -webkit-transform: scale(0.5) !important;
            transform: scale(0.5) !important;
    left: -15%;
  }
}

#banner .banerMain .banerThumbnail .bannerElementBox1 h2 {
  font-size: 18px;
  color: #036cf5;
  font-weight: 600;
}

#banner .banerMain .banerThumbnail .bannerElementBox1 p {
  font-size: 16px;
  opacity: 0.7;
  margin-top: 10px;
}

#banner .banerMain .banerThumbnail .bannerElementBox2 {
  position: absolute;
  padding: 15px;
  background: white;
  width: 250px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  right: 150px;
  top: 15%;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.151);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.151);
}

@media (max-width: 1440px) {
  #banner .banerMain .banerThumbnail .bannerElementBox2 {
    right: 50px;
  }
}

@media (max-width: 480px) {
  #banner .banerMain .banerThumbnail .bannerElementBox2 {
    -webkit-transform: scale(0.5) !important;
            transform: scale(0.5) !important;
    right: -15%;
  }
}

#banner .banerMain .banerThumbnail .bannerElementBox2 h2 {
  font-size: 18px;
  color: #f5a003;
  font-weight: 600;
}

#banner .banerMain .banerThumbnail .bannerElementBox2 p {
  font-size: 16px;
  opacity: 0.7;
  margin-top: 10px;
}

#banner .banerMain .banerThumbnail .bannerElementBox3 {
  position: absolute;
  padding: 15px;
  background: #ffffff;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  right: 150px;
  bottom: 5%;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.151);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.151);
}

@media (max-width: 1024px) {
  #banner .banerMain .banerThumbnail .bannerElementBox3 {
    right: 300px;
  }
}

@media (max-width: 768px) {
  #banner .banerMain .banerThumbnail .bannerElementBox3 {
    right: 150px;
  }
}

@media (max-width: 480px) {
  #banner .banerMain .banerThumbnail .bannerElementBox3 {
    -webkit-transform: scale(0.5) !important;
            transform: scale(0.5) !important;
    right: 5%;
  }
}

#banner .banerMain .banerThumbnail .bannerElementBox3 h2 {
  font-size: 18px;
  color: #04bb04;
  font-weight: 600;
}

#banner .banerMain .banerThumbnail .bannerElementBox3 .bannerElementBoxImg {
  width: 150px;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  #banner .banerMain .banerThumbnail .bannerElementBox3 .bannerElementBoxImg {
    width: 100px;
  }
}

#banner .banerMain .banerThumbnail .bannerElementBox3 .bannerElementBoxImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

#achievements {
  width: 100%;
  padding: 100px 0px;
  background: url(../../assets/images/wave.svg);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 768px) {
  #achievements {
    padding: 50px 0px;
  }
}

#achievements .achievementsMain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#achievements .achievementsMain .achievementsBox {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24%;
          flex: 0 0 24%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1024px) {
  #achievements .achievementsMain .achievementsBox {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 48%;
            flex: 0 0 48%;
    margin-top: 100px;
  }
  #achievements .achievementsMain .achievementsBox:nth-child(1) {
    margin-top: 0;
  }
  #achievements .achievementsMain .achievementsBox:nth-child(2) {
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  #achievements .achievementsMain .achievementsBox {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
  #achievements .achievementsMain .achievementsBox:nth-child(2) {
    margin-top: 100px;
  }
}

#achievements .achievementsMain .achievementsBox h1 {
  color: white;
  font-size: 3em;
  font-weight: 600;
  text-align: center;
}

#achievements .achievementsMain .achievementsBox p {
  color: white;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-top: 20px;
}

#about {
  width: 100%;
  padding: 100px 0px;
}

@media (max-width: 768px) {
  #about {
    padding: 50px 0px;
  }
}

#about .aboutMain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#about .aboutMain h1 {
  font-size: 3em;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 480px) {
  #about .aboutMain h1 {
    font-size: 2.5em;
    text-align: left;
    line-height: 1.5;
  }
}

#about .aboutMain p {
  width: 70%;
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 30px;
  opacity: 0.7;
}

@media (max-width: 1024px) {
  #about .aboutMain p {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #about .aboutMain p {
    text-align: left;
    line-height: 1.7;
  }
}

#about .aboutMain .aboutDetails {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 150px;
}

@media (max-width: 1024px) {
  #about .aboutMain .aboutDetails {
    margin-top: 100px;
  }
}

#about .aboutMain .aboutDetails .aboutDetailsThumbnail {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
  background: #002147;
  border-radius: 10px;
  position: relative;
  z-index: 0;
}

@media (max-width: 1024px) {
  #about .aboutMain .aboutDetails .aboutDetailsThumbnail {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    height: 450px;
  }
}

@media (max-width: 480px) {
  #about .aboutMain .aboutDetails .aboutDetailsThumbnail {
    height: 250px;
  }
}

#about .aboutMain .aboutDetails .aboutDetailsThumbnail .aboutThumbnailElement {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #7482ff;
  left: 100px;
  top: 100px;
  z-index: -1;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  #about .aboutMain .aboutDetails .aboutDetailsThumbnail .aboutThumbnailElement {
    left: 50px;
    top: 50px;
  }
}

#about .aboutMain .aboutDetails .aboutDetailsThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}

#about .aboutMain .aboutDetails .aboutDetailsContent {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (max-width: 1024px) {
  #about .aboutMain .aboutDetails .aboutDetailsContent {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    margin-top: 100px;
  }
}

#about .aboutMain .aboutDetails .aboutDetailsContent h2 {
  font-size: 2.5em;
  font-weight: 500;
  color: #002147;
}

#about .aboutMain .aboutDetails .aboutDetailsContent p {
  text-align: left;
  width: 100%;
  line-height: 1.7;
  font-size: 16px;
  opacity: 0.6;
}

#about .aboutMain .aboutDetails .aboutDetailsContent a {
  text-decoration: underline;
  margin-top: 50px;
  width: 100px;
  color: #7482ff;
  font-size: 16px;
  cursor: pointer;
}

#about .aboutMain .aboutDetails .aboutDetailsContent a:hover {
  color: #002147;
}

#courses {
  width: 100%;
  padding: 100px 0px;
  margin-top: 100px;
}

@media (max-width: 1024px) {
  #courses {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  #courses {
    padding: 50px 0px;
  }
}

#courses h1 {
  font-size: 3em;
  font-weight: 600;
}

@media (max-width: 480px) {
  #courses h1 {
    font-size: 2.5em;
    text-align: left;
    line-height: 1.5;
  }
}

#courses .coursesMain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#courses .coursesMain .coursesBox {
  cursor: pointer;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 50px;
  border-radius: 10px;
  overflow: hidden;
  background: white;
  -webkit-box-shadow: 0px 5px 28px 3px rgba(0, 0, 0, 0.18);
          box-shadow: 0px 5px 28px 3px rgba(0, 0, 0, 0.18);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
}

@media (max-width: 1024px) {
  #courses .coursesMain .coursesBox {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 48%;
            flex: 0 0 48%;
  }
}

@media (max-width: 768px) {
  #courses .coursesMain .coursesBox {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}

#courses .coursesMain .coursesBox:hover {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.082);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.082);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#courses .coursesMain .coursesBox .coursesBoxThumbnail {
  width: 100%;
  height: 300px;
  background: #7482ff;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 480px) {
  #courses .coursesMain .coursesBox .coursesBoxThumbnail {
    height: 200px;
  }
}

#courses .coursesMain .coursesBox .coursesBoxThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#courses .coursesMain .coursesBox .coursesBoxContent {
  width: 100%;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#courses .coursesMain .coursesBox .coursesBoxContent h2 {
  font-size: 22px;
  font-weight: 500;
  color: black;
}

@media (max-width: 480px) {
  #courses .coursesMain .coursesBox .coursesBoxContent h2 {
    font-size: 20px;
  }
}

#courses .coursesMain .coursesBox .coursesBoxContent .courseCount {
  background: rgba(0, 89, 255, 0.096);
  border: 1px solid #7482ff;
  width: 150px;
  padding: 10px;
  border-radius: 10px;
  color: #7482ff;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
}

#services {
  width: 100%;
  padding: 100px 0px;
  background: #7482ff;
}

@media (max-width: 768px) {
  #services {
    padding: 50px 0px;
  }
}

#services h1 {
  font-size: 3em;
  font-weight: 600;
  color: white;
  text-align: center;
}

@media (max-width: 480px) {
  #services h1 {
    font-size: 2.5em;
    line-height: 1.5;
  }
}

#services .servicesMain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 20px;
  position: relative;
}

#services .servicesMain::after {
  content: '';
  height: 0;
  width: 24%;
}

@media (max-width: 1024px) {
  #services .servicesMain::after {
    width: 32%;
  }
}

#services .servicesMain .servicesBox {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24%;
          flex: 0 0 24%;
  background: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 1024px) {
  #services .servicesMain .servicesBox {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 32%;
            flex: 0 0 32%;
  }
}

@media (max-width: 768px) {
  #services .servicesMain .servicesBox {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 48%;
            flex: 0 0 48%;
  }
}

@media (max-width: 480px) {
  #services .servicesMain .servicesBox {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}

#services .servicesMain .servicesBox:hover {
  margin-top: 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#services .servicesMain .servicesBox h2 {
  font-size: 22px;
  font-weight: 600;
  background: -webkit-linear-gradient(45deg, #7482ff, #dc52ff, #33daf1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#services .servicesMain .servicesBox p {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 15px;
  opacity: 0.7;
  color: black;
  height: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

#workingProcess {
  width: 100%;
  padding: 100px 0px;
  background: #f8f9ff;
}

@media (max-width: 768px) {
  #workingProcess {
    padding: 50px 0px;
  }
}

#workingProcess h1 {
  font-size: 3em;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 480px) {
  #workingProcess h1 {
    font-size: 2.5em;
    text-align: left;
    line-height: 1.5;
  }
}

#workingProcess .workingProcessMain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 768px) {
  #workingProcess .workingProcessMain {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

#workingProcess .workingProcessMain .workingProcessGraph {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 100px;
}

@media (max-width: 768px) {
  #workingProcess .workingProcessMain .workingProcessGraph {
    width: 10%;
    -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
            justify-content: inherit;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 480px) {
  #workingProcess .workingProcessMain .workingProcessGraph {
    margin-top: 50px;
  }
}

#workingProcess .workingProcessMain .workingProcessGraph .workingProcessGraphBox {
  width: 33%;
  height: 10px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 768px) {
  #workingProcess .workingProcessMain .workingProcessGraph .workingProcessGraphBox {
    width: 10px;
    height: 200px;
    margin-top: 10px;
    display: block;
  }
  #workingProcess .workingProcessMain .workingProcessGraph .workingProcessGraphBox:nth-child(1) {
    margin-top: 0;
  }
}

#workingProcess .workingProcessMain .workingProcessGraph .workingProcessGraphBox:nth-child(1) {
  background: #7482ff;
}

#workingProcess .workingProcessMain .workingProcessGraph .workingProcessGraphBox:nth-child(1) span {
  background: #7482ff;
}

#workingProcess .workingProcessMain .workingProcessGraph .workingProcessGraphBox:nth-child(2) {
  background: #dc52ff;
}

#workingProcess .workingProcessMain .workingProcessGraph .workingProcessGraphBox:nth-child(2) span {
  background: #dc52ff;
}

#workingProcess .workingProcessMain .workingProcessGraph .workingProcessGraphBox:nth-child(3) {
  background: #33daf1;
}

#workingProcess .workingProcessMain .workingProcessGraph .workingProcessGraphBox:nth-child(3) span {
  background: #33daf1;
}

#workingProcess .workingProcessMain .workingProcessGraph .workingProcessGraphBox span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid #fafafa;
}

@media (max-width: 768px) {
  #workingProcess .workingProcessMain .workingProcessGraph .workingProcessGraphBox span {
    display: block;
    margin-top: calc(100px - 15px);
    margin-left: calc(5px - 15px);
  }
}

#workingProcess .workingProcessMain .workingProcessBoxMain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 30px;
}

@media (max-width: 768px) {
  #workingProcess .workingProcessMain .workingProcessBoxMain {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

@media (max-width: 480px) {
  #workingProcess .workingProcessMain .workingProcessBoxMain {
    margin-top: 0;
  }
}

#workingProcess .workingProcessMain .workingProcessBoxMain .workingProcessBox {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
  border-radius: 10px;
  background: white;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.041);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.041);
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1024px) {
  #workingProcess .workingProcessMain .workingProcessBoxMain .workingProcessBox {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 32%;
            flex: 0 0 32%;
  }
}

@media (max-width: 768px) {
  #workingProcess .workingProcessMain .workingProcessBoxMain .workingProcessBox {
    -webkit-box-flex: inherit;
        -ms-flex: inherit;
            flex: inherit;
    width: 100%;
    margin-top: 120px;
  }
}

@media (max-width: 480px) {
  #workingProcess .workingProcessMain .workingProcessBoxMain .workingProcessBox {
    margin-top: 100px;
  }
  #workingProcess .workingProcessMain .workingProcessBoxMain .workingProcessBox:nth-child(2) {
    margin-top: 130px;
  }
  #workingProcess .workingProcessMain .workingProcessBoxMain .workingProcessBox:nth-child(3) {
    margin-top: 120px;
  }
}

#workingProcess .workingProcessMain .workingProcessBoxMain .workingProcessBox:nth-child(1) .workingProcessBoxThumbnail {
  background: #7482ff28;
  color: #7482ff;
}

#workingProcess .workingProcessMain .workingProcessBoxMain .workingProcessBox:nth-child(2) .workingProcessBoxThumbnail {
  background: #dc52ff27;
  color: #dc52ff;
}

#workingProcess .workingProcessMain .workingProcessBoxMain .workingProcessBox:nth-child(3) .workingProcessBoxThumbnail {
  background: #33dbf127;
  color: #33daf1;
}

#workingProcess .workingProcessMain .workingProcessBoxMain .workingProcessBox .workingProcessBoxThumbnail {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 25px;
}

#workingProcess .workingProcessMain .workingProcessBoxMain .workingProcessBox h3 {
  margin-left: 20px;
  font-size: 22px;
  font-weight: 500;
}

#gallery {
  width: 100%;
  padding: 100px 0px;
}

@media (max-width: 768px) {
  #gallery {
    padding: 50px 0px;
  }
}

#gallery .galleryBox {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 480px) {
  #gallery .galleryBox {
    height: 200px;
  }
}

#gallery .galleryBox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#gallery .owl-item:nth-child(odd) .galleryBox {
  margin-top: 100px;
}

#gallery .owl-dot, #gallery .owl-nav {
  display: none;
}

#testimonial {
  width: 100%;
  padding: 100px 0px;
  position: relative;
  z-index: 0;
}

@media (max-width: 768px) {
  #testimonial {
    padding: 50px 0px;
  }
}

#testimonial h1 {
  font-size: 3em;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 480px) {
  #testimonial h1 {
    font-size: 2.5em;
    text-align: left;
    line-height: 1.5;
  }
}

#testimonial .testimonialMain {
  width: 100%;
  margin-top: 100px;
  padding: 0px 25%;
}

@media (max-width: 1024px) {
  #testimonial .testimonialMain {
    padding: 0px;
  }
}

#testimonial .testimonialMain .testimonialBox {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 15px;
}

#testimonial .testimonialMain .testimonialBox .testimonialBoxProfile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.096);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.096);
  overflow: hidden;
}

#testimonial .testimonialMain .testimonialBox .testimonialBoxProfile img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#testimonial .testimonialMain .testimonialBox .testimonialBoxContent {
  width: 100%;
  padding: 20px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.048);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.048);
  background: white;
  border-radius: 20px;
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#testimonial .testimonialMain .testimonialBox .testimonialBoxContent p {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.7;
  text-align: center;
}

@media (max-width: 480px) {
  #testimonial .testimonialMain .testimonialBox .testimonialBoxContent p {
    font-size: 18px;
  }
}

#testimonial .testimonialMain .testimonialBox .testimonialBoxContent h3 {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
}

#testimonial .testimonialMain .testimonialBox .testimonialBoxContent span {
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
  opacity: 0.6;
}

#testimonial .owl-dot, #testimonial .owl-nav {
  display: none;
}

#testimonial .testimonialElement {
  position: absolute;
  border-radius: 50%;
  border: 3px solid white;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.096);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.096);
  overflow: hidden;
}

#testimonial .testimonialElement img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#testimonial .testimonialElement1 {
  width: 35px;
  height: 35px;
  background: white;
  left: 10%;
  top: 30%;
}

#testimonial .testimonialElement2 {
  width: 55px;
  height: 55px;
  background: white;
  left: 15%;
  top: 45%;
}

#testimonial .testimonialElement3 {
  width: 40px;
  height: 40px;
  background: white;
  left: 12%;
  bottom: 10%;
}

#testimonial .testimonialElement4 {
  width: 35px;
  height: 35px;
  background: white;
  right: 10%;
  top: 30%;
}

#testimonial .testimonialElement5 {
  width: 55px;
  height: 55px;
  background: white;
  right: 15%;
  top: 45%;
}

#testimonial .testimonialElement6 {
  width: 40px;
  height: 40px;
  background: white;
  right: 12%;
  bottom: 10%;
}

#contact {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 100px;
  background: #f8f9ff;
}

@media (max-width: 1024px) {
  #contact {
    margin-top: 0;
  }
}

#contact .contactThumbnail {
  width: 60%;
  background: #7482ff;
}

@media (max-width: 1024px) {
  #contact .contactThumbnail {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 480px) {
  #contact .contactThumbnail {
    height: 250px;
  }
}

#contact .contactThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#contact .contactForm {
  width: 40%;
  padding: 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 1024px) {
  #contact .contactForm {
    width: 100%;
    padding: 100px 5%;
  }
}

@media (max-width: 480px) {
  #contact .contactForm {
    padding: 50px 5%;
  }
}

#contact .contactForm h1 {
  font-size: 3em;
  font-weight: 600;
}

@media (max-width: 480px) {
  #contact .contactForm h1 {
    font-size: 2.5em;
    text-align: left;
    line-height: 1.5;
  }
}

#contact .contactForm p {
  font-size: 16px;
  line-height: 1.7;
  margin-top: 20px;
  opacity: 0.6;
}

#contact .contactForm form {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
}

#contact .contactForm form .formGroup {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 30px;
}

#contact .contactForm form .formGroup label {
  font-size: 16px;
  color: #002147;
}

#contact .contactForm form .formGroup input {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  border: 1px solid white;
  outline: none;
  background: white;
  padding: 15px;
  font-size: 22px;
  font-weight: 400;
  margin-top: 10px;
}

#contact .contactForm form .formGroup input:focus {
  border: 1px solid #7482ff;
}

#contact .contactForm form .formGroup textarea {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  border: 1px solid white;
  outline: none;
  background: white;
  padding: 15px;
  font-size: 22px;
  font-weight: 400;
  margin-top: 10px;
}

#contact .contactForm form .formGroup textarea:focus {
  border: 1px solid #7482ff;
}

#contact .contactForm form button {
  width: 100%;
  padding: 17px;
  background: #7482ff;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#contact .contactForm form button:hover {
  background: #002147;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

footer {
  width: 100%;
  padding: 100px 0px 50px 0px;
  background: #002147;
}

@media (max-width: 480px) {
  footer {
    padding: 50px 0px;
  }
}

footer .footerMain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

footer .footerMain .footerBox1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 1024px) {
  footer .footerMain .footerBox1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 45%;
            flex: 0 0 45%;
  }
}

@media (max-width: 768px) {
  footer .footerMain .footerBox1 {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}

footer .footerMain .footerBox1 .footerLogo {
  width: 250px;
}

footer .footerMain .footerBox1 .footerLogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

footer .footerMain .footerBox1 p {
  font-size: 12px;
  color: #a8a8a8;
  font-weight: 300;
  line-height: 1.7;
  margin-top: 20px;
  width: 70%;
}

@media (max-width: 1024px) {
  footer .footerMain .footerBox1 p {
    width: 100%;
  }
}

footer .footerMain .footerBox1 .footerSocialLink {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
}

footer .footerMain .footerBox1 .footerSocialLink .footerSocialLinkBox {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #7482ff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-right: 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

footer .footerMain .footerBox1 .footerSocialLink .footerSocialLinkBox:hover {
  margin-top: -5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

footer .footerMain .footerBox2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 15%;
          flex: 0 0 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 768px) {
  footer .footerMain .footerBox2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    margin-top: 50px;
  }
}

footer .footerMain .footerBox2 h2 {
  font-size: 14px;
  font-weight: 400;
  color: white;
  margin-bottom: 10px;
}

footer .footerMain .footerBox2 ul {
  width: 100%;
}

footer .footerMain .footerBox2 ul li {
  list-style: none;
}

footer .footerMain .footerBox2 ul li a {
  text-decoration: none;
  color: #a8a8a8 !important;
  font-size: 14px;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 300;
}

footer .footerMain .footerBox2 ul li a:hover {
  color: #7482ff;
}

footer .footerMain .footerBox3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 1024px) {
  footer .footerMain .footerBox3 {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    margin-top: 50px;
  }
}

footer .footerMain .footerBox3 h2 {
  font-size: 14px;
  font-weight: 400;
  color: #7482ff;
  margin-bottom: 10px;
  margin-top: 20px;
}

footer .footerMain .footerBox3 h2:nth-child(1) {
  margin-top: 0;
}

footer .footerMain .footerBox3 a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  margin-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5;
  font-weight: 300;
}

footer .footerMain .footerBox3 a:hover {
  color: #7482ff;
}

footer .footerStrip {
  width: 100%;
  margin-top: 100px;
  background: rgba(0, 0, 0, 0.274);
  padding: 20px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 480px) {
  footer .footerStrip {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

footer .footerStrip p {
  color: #a8a8a8;
  font-size: 14px;
  font-weight: 300;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 480px) {
  footer .footerStrip p {
    width: 100%;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 10px;
  }
  footer .footerStrip p:nth-child(1) {
    margin-top: 0px;
  }
}

footer .footerStrip p span {
  color: red;
  font-size: 16px;
  margin: 0px 10px;
}

footer .footerStrip p a {
  text-decoration: none;
  color: white;
}

#aboutPageBanner {
  width: 100%;
  margin-top: 80px;
  padding: 100px 0px;
}

@media (max-width: 768px) {
  #aboutPageBanner {
    margin-top: 60px;
    padding: 50px 0px;
  }
}

#aboutPageBanner .aboutPageBannerMain {
  width: 100%;
  position: relative;
  min-height: 75vh;
}

@media (max-width: 768px) {
  #aboutPageBanner .aboutPageBannerMain {
    position: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

#aboutPageBanner .aboutPageBannerMain .aboutPageBannerContent {
  position: absolute;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  left: 0;
  z-index: 1;
}

@media (max-width: 768px) {
  #aboutPageBanner .aboutPageBannerMain .aboutPageBannerContent {
    width: 100%;
    position: inherit;
  }
}

#aboutPageBanner .aboutPageBannerMain .aboutPageBannerContent h1 {
  font-size: 6em;
  margin-top: 100px;
}

@media (max-width: 768px) {
  #aboutPageBanner .aboutPageBannerMain .aboutPageBannerContent h1 {
    margin-top: 0;
    font-size: 4em;
  }
}

@media (max-width: 480px) {
  #aboutPageBanner .aboutPageBannerMain .aboutPageBannerContent h1 {
    font-size: 2.5em;
  }
}

#aboutPageBanner .aboutPageBannerMain .aboutPageBannerThumbnail {
  width: 65%;
  position: absolute;
  right: 0;
  z-index: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 768px) {
  #aboutPageBanner .aboutPageBannerMain .aboutPageBannerThumbnail {
    width: 100%;
    position: inherit;
    margin-top: 50px;
  }
}

#aboutPageBanner .aboutPageBannerMain .aboutPageBannerThumbnail .aboutPageBannerThumbnailBox {
  width: 100%;
  background: #7482ff;
  border-radius: 10px;
  overflow: hidden;
  height: 60vh;
}

@media (max-width: 768px) {
  #aboutPageBanner .aboutPageBannerMain .aboutPageBannerThumbnail .aboutPageBannerThumbnailBox {
    height: 400px;
  }
}

@media (max-width: 480px) {
  #aboutPageBanner .aboutPageBannerMain .aboutPageBannerThumbnail .aboutPageBannerThumbnailBox {
    height: 250px;
  }
}

#aboutPageBanner .aboutPageBannerMain .aboutPageBannerThumbnail .aboutPageBannerThumbnailBox img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#aboutPageBanner .aboutPageBannerMain .aboutPageBannerThumbnail p {
  font-size: 18px;
  line-height: 2;
  margin-top: 30px;
  opacity: 0.6;
  font-weight: 400;
}

#aboutDetails {
  width: 100%;
  padding: 100px 0px;
}

#aboutDetails .aboutDetailsMain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#aboutDetails .aboutDetailsMain .aboutDetailsThumbnail {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 768px) {
  #aboutDetails .aboutDetailsMain .aboutDetailsThumbnail {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}

#aboutDetails .aboutDetailsMain .aboutDetailsThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#aboutDetails .aboutDetailsMain .aboutDetailContent {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 50px 0px;
}

@media (max-width: 768px) {
  #aboutDetails .aboutDetailsMain .aboutDetailContent {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}

#aboutDetails .aboutDetailsMain .aboutDetailContent h2 {
  font-size: 2.5em;
  font-weight: 500;
  color: #002147;
}

#aboutDetails .aboutDetailsMain .aboutDetailContent p {
  text-align: left;
  width: 100%;
  line-height: 1.7;
  font-size: 16px;
  opacity: 0.6;
  margin-top: 20px;
}

#aboutDetails .aboutMissionVision {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 40px;
}

@media (max-width: 768px) {
  #aboutDetails .aboutMissionVision {
    margin-top: 0;
  }
}

#aboutDetails .aboutMissionVision .aboutMissionVisionBox {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 50px;
}

@media (max-width: 768px) {
  #aboutDetails .aboutMissionVision .aboutMissionVisionBox {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}

#aboutDetails .aboutMissionVision .aboutMissionVisionBox h2 {
  font-size: 1.8em;
  font-weight: 500;
}

#aboutDetails .aboutMissionVision .aboutMissionVisionBox ul {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#aboutDetails .aboutMissionVision .aboutMissionVisionBox ul li {
  list-style: none;
  margin-top: 20px;
  line-height: 1.7;
  font-size: 16px;
}

.coursePage {
  margin-top: 80px !important;
}

@media (max-width: 768px) {
  .coursePage {
    margin-top: 60px !important;
  }
}

#testimonialPage {
  width: 100%;
  margin-top: 80px;
  padding: 100px 20%;
}

@media (max-width: 768px) {
  #testimonialPage {
    padding: 50px 5%;
  }
}

#testimonialPage .testimonialPageBox {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 150px;
}

#testimonialPage .testimonialPageBox:nth-child(1) {
  margin-top: 0;
}

#testimonialPage .testimonialPageBox .testimonialPageBoxProfile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.096);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.096);
  overflow: hidden;
}

#testimonialPage .testimonialPageBox .testimonialPageBoxProfile img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#testimonialPage .testimonialPageBox .testimonialPageBoxContent {
  width: 100%;
  padding: 20px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.048);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.048);
  background: white;
  border-radius: 20px;
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#testimonialPage .testimonialPageBox .testimonialPageBoxContent p {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.7;
  text-align: center;
}

@media (max-width: 480px) {
  #testimonialPage .testimonialPageBox .testimonialPageBoxContent p {
    font-size: 18px;
  }
}

#testimonialPage .testimonialPageBox .testimonialPageBoxContent h3 {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
}

#testimonialPage .testimonialPageBox .testimonialPageBoxContent span {
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
  opacity: 0.6;
}

#galleryPage {
  width: 100%;
  margin-top: 80px;
  padding: 100px 0px;
}

@media (max-width: 768px) {
  #galleryPage {
    margin-top: 60px;
    padding: 50px 0px;
  }
}

#galleryPage h1 {
  font-size: 3em;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 480px) {
  #galleryPage h1 {
    font-size: 2.5em;
    text-align: left;
    line-height: 1.5;
  }
}

#galleryPage .galleryPageMain {
  width: 100%;
  margin-top: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
}

@media (max-width: 480px) {
  #galleryPage .galleryPageMain {
    margin-top: 30px;
  }
}

#galleryPage .galleryPageMain::after {
  content: '';
  height: 0;
  width: 24%;
}

#galleryPage .galleryPageMain img {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24%;
          flex: 0 0 24%;
  border-radius: 10px;
  overflow: hidden;
  height: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px !important;
  cursor: pointer;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 1024px) {
  #galleryPage .galleryPageMain img {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
  }
}

@media (max-width: 768px) {
  #galleryPage .galleryPageMain img {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 48%;
            flex: 0 0 48%;
  }
}

@media (max-width: 480px) {
  #galleryPage .galleryPageMain img {
    height: 150px;
    margin-top: 20px;
  }
}

#galleryPage .galleryPageMain .dummyGalleryPageBox {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24%;
          flex: 0 0 24%;
  height: 0;
}

@media (max-width: 1024px) {
  #galleryPage .galleryPageMain .dummyGalleryPageBox {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
  }
}

#galleryPage .styles-module_wrapper__1I_qj {
  z-index: 200 !important;
}

#galleryPage .styles-module_wrapper__1I_qj img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-box-flex: inherit;
      -ms-flex: inherit;
          flex: inherit;
}

#contactPage {
  width: 100%;
  margin-top: 80px;
  padding: 100px 0px;
}

@media (max-width: 768px) {
  #contactPage {
    padding: 50px 0px;
    margin-top: 60px;
  }
}

#contactPage h1 {
  font-size: 3em;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 480px) {
  #contactPage h1 {
    font-size: 2.5em;
    line-height: 1.5;
  }
}

#contactPage p {
  font-size: 20px;
  font-weight: 300;
  margin-top: 30px;
  text-align: center;
  opacity: 0.7;
  line-height: 1.7;
}

@media (max-width: 480px) {
  #contactPage p {
    font-size: 18px;
    margin-top: 20px;
  }
}

#contactPage .contactPageMain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 50px;
}

#contactPage .contactPageMain .contactPageBox {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 50px;
}

@media (max-width: 1024px) {
  #contactPage .contactPageMain .contactPageBox {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}

@media (max-width: 480px) {
  #contactPage .contactPageMain .contactPageBox {
    margin-top: 60px;
  }
}

#contactPage .contactPageMain .contactPageBox span {
  font-size: 30px;
  color: #7482ff;
}

#contactPage .contactPageMain .contactPageBox h3 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
  opacity: 0.6;
}

#contactPage .contactPageMain .contactPageBox a {
  text-align: center;
  text-decoration: none;
  color: #002147;
  font-size: 25px;
  margin-top: 30px;
  line-height: 1.7;
}

@media (max-width: 1440px) {
  #contactPage .contactPageMain .contactPageBox a {
    font-size: 22px;
  }
}

@media (max-width: 480px) {
  #contactPage .contactPageMain .contactPageBox a {
    margin-top: 20px;
  }
}

#contactPage .contactPageMain .contactPageBox a:hover {
  color: #7482ff;
}

#innerCourseBanner {
  width: 100%;
  padding: 100px 0px;
  margin-top: 80px;
  background: #7482ff;
  background-image: url(../../assets/images/innerServiceShape.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 768px) {
  #innerCourseBanner {
    padding: 50px 0px;
    margin-top: 60px;
  }
}

#innerCourseBanner h1 {
  font-size: 3.5em;
  font-weight: 500;
  color: white;
}

@media (max-width: 768px) {
  #innerCourseBanner h1 {
    font-size: 2.5em;
  }
}

@media (max-width: 480px) {
  #innerCourseBanner h1 {
    font-size: 2.5em;
    line-height: 1.5;
  }
}

#innerCourseList {
  width: 100%;
  padding: 80px 0px 100px 0px;
  background: rgba(246, 248, 248, 0.856);
}

@media (max-width: 480px) {
  #innerCourseList {
    padding: 50px 0px;
  }
}

.innerCourseListFilter {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  display: none;
}

.innerCourseListFilter .innerCourseListFilterBox {
  background: white;
  padding: 12px 25px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.innerCourseListFilter .innerCourseListFilterBox:hover {
  background: #fbfbfb;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.innerCourseListFilter .innerCourseListFilterBox i {
  margin-right: 5px;
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.innerCourseListFilter .innerCourseListFilterBox p {
  color: #616161;
}

.innerCourseTab {
  width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: fixed;
  z-index: 110;
  background: white;
  top: 80px;
  right: -110%;
  padding-bottom: 10px;
  overflow-Y: auto;
  height: calc(100vh - 80px);
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.innerCourseTab::-webkit-scrollbar {
  width: 5px;
}

.innerCourseTab .innerCourseTabBox {
  width: 100%;
  padding: 10px 20px;
  background: white;
  border-radius: 10px;
  font-size: 14px;
  margin-top: 20px;
}

.innerCourseTab .innerCourseTabBox input[type="radio"] {
  display: none;
}

.innerCourseTab .innerCourseTabBox input[type="radio"] + label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.innerCourseTab .innerCourseTabBox input[type="radio"] + label:before,
.innerCourseTab .innerCourseTabBox input[type="radio"] + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  text-align: center;
  color: white;
  border-radius: 50%;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.innerCourseTab .innerCourseTabBox input[type="radio"] + label:before {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
          box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
  border: 1.5px solid #7482ff;
}

.innerCourseTab .innerCourseTabBox input[type="radio"] + label:hover:before {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #7482ff;
          box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #7482ff;
}

.innerCourseTab .innerCourseTabBox input[type="radio"]:checked + label:before {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #7482ff;
          box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #7482ff;
}

.innerCourseTabActive {
  right: 0;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.innerCourseListMain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 50px;
}

.innerCourseListMain .innerCourseListBox {
  background: white;
  width: 100%;
  margin-top: 50px;
  border-radius: 10px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.innerCourseListMain .innerCourseListBox:nth-child(1) {
  margin-top: 0;
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxHead {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxHead h1 {
  font-size: 2.3em;
  color: black;
  font-weight: 500;
}

@media (max-width: 1024px) {
  .innerCourseListMain .innerCourseListBox .innerCourseListBoxHead h1 {
    font-size: 2em;
  }
}

@media (max-width: 768px) {
  .innerCourseListMain .innerCourseListBox .innerCourseListBoxHead h1 {
    font-size: 30px;
  }
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxBody {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 20px;
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxBody span {
  font-size: 20px;
  color: #7482ff;
  margin-top: 30px;
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxBody span:nth-child(1) {
  margin-top: 0;
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxBody p {
  font-size: 18px;
  margin-top: 15px;
  opacity: 0.8;
  color: black;
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxBody .innerCourseListBoxBodyDuration {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 20px;
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxBody .innerCourseListBoxBodyDuration .innerCourseListBoxBodyDurationBox {
  width: calc(100% / 3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #eee;
}

@media (max-width: 1024px) {
  .innerCourseListMain .innerCourseListBox .innerCourseListBoxBody .innerCourseListBoxBodyDuration .innerCourseListBoxBodyDurationBox {
    width: calc(100% / 2);
  }
}

@media (max-width: 768px) {
  .innerCourseListMain .innerCourseListBox .innerCourseListBoxBody .innerCourseListBoxBodyDuration .innerCourseListBoxBodyDurationBox {
    width: 100%;
  }
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxBody .innerCourseListBoxBodyDuration .innerCourseListBoxBodyDurationBox ul {
  width: 100%;
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxBody .innerCourseListBoxBodyDuration .innerCourseListBoxBodyDurationBox ul li {
  list-style: none;
  font-size: 18px;
  color: black;
  line-height: 1.7;
  padding: 10px 20px;
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxBody .innerCourseListBoxBodyDuration .innerCourseListBoxBodyDurationBox ul li:nth-child(1) {
  border-bottom: 1px solid #eee;
  padding: 15px 20px;
  font-size: 22px;
  font-weight: 500;
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxBody .innerCourseContact {
  text-decoration: none;
  margin-top: 40px;
  padding: 15px 30px;
  background: #7482ff;
  color: white;
  font-size: 16px;
  width: 200px;
  text-align: center;
  border-radius: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.innerCourseListMain .innerCourseListBox .innerCourseListBoxBody .innerCourseContact:hover {
  background: #002147;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#servicesPage {
  width: 100%;
  margin-top: 80px;
  padding: 100px 0px;
}

@media (max-width: 768px) {
  #servicesPage {
    padding: 50px 0px;
    margin-top: 60px;
  }
}

#servicesPage h1 {
  font-size: 25px;
  width: 50%;
  line-height: 1.5;
  font-weight: 500;
}

@media (max-width: 1024px) {
  #servicesPage h1 {
    width: 100%;
  }
}

#servicesPage .servicesPageMain {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 100px;
}

#servicesPage .servicesPageMain .servicesPageThumbnail {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
  min-height: 600px;
  background: black;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 1024px) {
  #servicesPage .servicesPageMain .servicesPageThumbnail {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 45%;
            flex: 0 0 45%;
  }
}

@media (max-width: 768px) {
  #servicesPage .servicesPageMain .servicesPageThumbnail {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    height: 350px;
    min-height: inherit;
  }
}

#servicesPage .servicesPageMain .servicesPageThumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#servicesPage .servicesPageMain .servicesPageContent {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 62%;
          flex: 0 0 62%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 1024px) {
  #servicesPage .servicesPageMain .servicesPageContent {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
}

@media (max-width: 768px) {
  #servicesPage .servicesPageMain .servicesPageContent {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    margin-top: 30px;
  }
}

#servicesPage .servicesPageMain .servicesPageContent h2 {
  font-size: 3em;
  font-weight: 600;
  background: -webkit-linear-gradient(45deg, #7482ff, #dc52ff, #33daf1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 480px) {
  #servicesPage .servicesPageMain .servicesPageContent h2 {
    font-size: 2em;
  }
}

#servicesPage .servicesPageMain .servicesPageContent p {
  font-size: 18px;
  line-height: 1.7;
  opacity: 0.7;
  margin-top: 20px;
  color: #000225;
}

@media (max-width: 480px) {
  #servicesPage .servicesPageMain .servicesPageContent p {
    font-size: 16px;
  }
}

#servicesPage .servicesPageMain .servicesPageContent .servicesPageContentBtns {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 40px;
}

@media (max-width: 480px) {
  #servicesPage .servicesPageMain .servicesPageContent .servicesPageContentBtns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

#servicesPage .servicesPageMain .servicesPageContent .servicesPageContentBtns .viewBtn {
  padding: 15px 30px;
  border-radius: 10px;
  background: #e9ebff;
  color: #7482ff;
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-right: 20px;
}

#servicesPage .servicesPageMain .servicesPageContent .servicesPageContentBtns .viewBtn:hover {
  background: #7482ff;
  color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 480px) {
  #servicesPage .servicesPageMain .servicesPageContent .servicesPageContentBtns .viewBtn {
    margin-right: 0;
    margin-top: 10px;
  }
}

#servicesPage .servicesPageMain .servicesPageContent .servicesPageContentBtns .erpBtn {
  padding: 15px 30px;
  border-radius: 10px;
  background: #be6afc;
  color: #fff;
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-right: 20px;
}

#servicesPage .servicesPageMain .servicesPageContent .servicesPageContentBtns .erpBtn:hover {
  background: #e9ebff;
  color: #7482ff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 480px) {
  #servicesPage .servicesPageMain .servicesPageContent .servicesPageContentBtns .erpBtn {
    margin-left: 0;
    margin-top: 10px;
  }
}

#servicesPage .servicesPageMain .servicesPageContent .servicesPageContentBtns .ContactBtn {
  padding: 15px 30px;
  border-radius: 10px;
  background: #7482ff;
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#servicesPage .servicesPageMain .servicesPageContent .servicesPageContentBtns .ContactBtn:hover {
  background: #e9ebff;
  color: #7482ff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 480px) {
  #servicesPage .servicesPageMain .servicesPageContent .servicesPageContentBtns .ContactBtn {
    margin-left: 0;
    margin-top: 10px;
  }
}
