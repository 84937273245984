// app style 
@import "../../App.scss";

// banner
#banner{
    width: 100%;
    margin-top: 80px;
    @media(max-width:768px){
        margin-top: 60px;
    }
    .banerMain{
        width: 100%;
        height: calc(100vh - 80px);
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1024px){
            height: auto;
        }
        .banerContent{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 50px 5%;
            @media(max-width:1024px){
                width: 100%;
                order: 2;
                padding: 100px 5%;
            }
            @media(max-width:768px){
                padding: 50px 5%;
            }
            @media(max-width:480px){
                
            }
            h1{
                font-size: 3.5em;
                font-weight: 600;
                @media(max-width:768px){
                    font-size: 3em;
                }
                @media(max-width:480px){
                    font-size: 2.5em;
                }
                span{
                    color: $primaryColor;
                }
            }
            p{
                font-size: 20px;
                margin-top: 30px;
                line-height: 1.7;
                width: 60%;
                opacity: 0.7;
                @media(max-width:768px){
                    width: 80%;
                }
                @media(max-width:480px){
                    width: 100%;
                }
            }
            a{
                text-decoration: none;
                width: 200px;
                background: $primaryColor;
                color: white;
                font-size: 18px;
                margin-top: 40px;
                text-align: center;
                padding: 17px;
                border-radius: 15px;
                transition: 0.3s;
                cursor: pointer;
                &:hover{
                    transition: 0.3s;
                    background: $secondaryColor;
                }
            }
        }
        .banerThumbnail{
            width: 50%;
            height: 100%;
            background: $primaryColor;
            padding: 0px 5%;
            position: relative;
            z-index: 0;
            @media(max-width:1024px){
                width: 100%;
                order: 1;
                height: 600px;
            }
            @media(max-width:480px){
                height: 350px;
            }
            .banerThumbnailBox{
                width: 100%;
                height: 100%;
                left: 0;
                position: absolute;
                bottom: 0;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .bannerElement1{
                position: absolute;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                background: #afb6f5f5;
                opacity: 0.2;
                top: 10%;
                z-index: -1;
                animation: flashUp 1.5s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                @keyframes flashUp {
                    from {transform: scale(0.9); }
                    to   { transform: scale(1.3); }
                }
            }
            .bannerElement2{
                position: absolute;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background: #afb6f5f5;
                opacity: 0.2;
                bottom: 15%;
                right: 18%;
                z-index: -1;
                animation: flashUp2 1.8s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                @keyframes flashUp2 {
                    from {transform: scale(0.9); }
                    to   { transform: scale(1.3); }
                }
            }
            .bannerElement3{
                position: absolute;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: #afb6f5f5;
                opacity: 0.2;
                top: 25%;
                right: 28%;
                z-index: -1;
                animation: flashUp3 1.3s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                @keyframes flashUp3 {
                    from {transform: scale(0.9); }
                    to   { transform: scale(1.3); }
                }
            }
            .bannerElement4{
                position: absolute;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #afb6f5f5;
                opacity: 0.2;
                bottom: 25%;
                left: 20%;
                z-index: -1;
                animation: flashUp4 1s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                @keyframes flashUp4 {
                    from {transform: scale(0.9); }
                    to   { transform: scale(1.3); }
                }
            }
            .bannerElementBox1{
                position: absolute;
                padding: 15px;
                background: white;
                width: 300px;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                left: -100px;
                bottom: 35%;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.151);
                @media(max-width:1024px){
                    left: 0px;
                }
                @media(max-width:768px){
                    left: 20px;
                }
                @media(max-width:480px){
                    transform: scale(0.5) !important;
                    left: -15%;
                }
                h2{
                    font-size: 18px;
                    color: rgb(3, 108, 245);
                    font-weight: 600;
                }
                p{
                    font-size: 16px;
                    opacity: 0.7;
                    margin-top:10px;
                }
            }
            .bannerElementBox2{
                position: absolute;
                padding: 15px;
                background: white;
                width: 250px;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                right: 150px;
                top: 15%;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.151);
                @media(max-width:1440px){
                    right: 50px;
                }
                @media(max-width:480px){
                    transform: scale(0.5) !important;
                    right: -15%;
                }
                h2{
                    font-size: 18px;
                    color: rgb(245, 160, 3);
                    font-weight: 600;
                }
                p{
                    font-size: 16px;
                    opacity: 0.7;
                    margin-top:10px;
                }
            }
            .bannerElementBox3{
                position: absolute;
                padding: 15px;
                background: #ffffff;
                // width: 150px;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                right: 150px;
                bottom: 5%;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.151);
                @media(max-width:1024px){
                    right: 300px;
                }
                @media(max-width:768px){
                    right: 150px;
                }
                @media(max-width:480px){
                    transform: scale(0.5) !important;
                    right: 5%;
                }
                h2{
                    font-size: 18px;
                    color: rgb(4, 187, 4);
                    font-weight: 600;
                }
                .bannerElementBoxImg{
                    width: 150px;
                    margin-top: 10px;
                    @media(max-width:1024px){
                        width: 100px;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

// achievements
#achievements{
    width: 100%;
    padding: 100px 0px;
    background: url(../../assets/images/wave.svg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media(max-width:768px){
        padding: 50px 0px;
    }
    .achievementsMain{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .achievementsBox{
            flex: 0 0 24%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media(max-width:1024px){
                flex: 0 0 48%;
                margin-top: 100px;
                &:nth-child(1){
                    margin-top: 0;
                }
                &:nth-child(2){
                    margin-top: 0;
                }
            }
            @media(max-width:480px){
                flex: 100%;
                &:nth-child(2){
                    margin-top: 100px;
                }
            }
            h1{
                color: white;
                font-size: 3em;
                font-weight: 600;
                text-align: center;
            }
            p{
                color: white;
                font-size: 20px;
                font-weight: 300;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
}

// about
#about{
    width: 100%;
    padding: 100px 0px;
    @media(max-width:768px){
        padding: 50px 0px;
    }
    .aboutMain{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1{
            font-size: 3em;
            font-weight: 600;
            text-align: center;
            @media(max-width:480px){
                font-size: 2.5em;
                text-align: left;
                line-height: 1.5;
            }
        }
        p{
            width: 70%;
            text-align: center;
            font-size: 18px;
            line-height: 1.5;
            margin-top: 30px;
            opacity: 0.7;
            @media(max-width:1024px){
                width: 100%;
            }
            @media(max-width:480px){
                text-align: left;
                line-height: 1.7;
            }
        }
        .aboutDetails{
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 150px;
            @media(max-width:1024px){
                margin-top: 100px;
            }
            .aboutDetailsThumbnail{
                flex: 0 0 45%;
                background: $secondaryColor;
                border-radius: 10px;
                position: relative;
                z-index: 0;
                @media(max-width:1024px){
                    flex: 100%;
                    height: 450px;
                }
                @media(max-width:480px){
                    height: 250px;
                }
                .aboutThumbnailElement{
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    background: $primaryColor;
                    left: 100px;
                    top: 100px;
                    z-index: -1;
                    border-radius: 10px;
                    @media(max-width:1024px){
                        left: 50px;
                        top: 50px;
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
            .aboutDetailsContent{
                flex: 0 0 45%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media(max-width:1024px){
                    flex: 100%;
                    margin-top: 100px;
                }
                h2{
                    font-size: 2.5em;
                    font-weight: 500;
                    color: $secondaryColor;
                }
                p{
                    text-align: left;
                    width: 100%;
                    line-height: 1.7;
                    font-size: 16px;
                    opacity: 0.6;
                }
                a{
                    text-decoration: underline;
                    margin-top: 50px;
                    width: 100px;
                    color: $primaryColor;
                    font-size: 16px;
                    cursor: pointer;
                    &:hover{
                        color: $secondaryColor;
                    }
                }
            }
        }
    }
}

// courses
#courses{
    width: 100%;
    padding: 100px 0px;
    margin-top: 100px;
    @media(max-width:1024px){
        margin-top: 0;
    }
    @media(max-width:768px){
        padding: 50px 0px;
    }
    h1{
        font-size: 3em;
        font-weight: 600;
        @media(max-width:480px){
            font-size: 2.5em;
            text-align: left;
            line-height: 1.5;
        }
    }
    .coursesMain{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .coursesBox{
            cursor: pointer;
            flex: 0 0 30%;
            display: flex;
            flex-direction: column;
            margin-top: 50px;
            border-radius: 10px;
            overflow: hidden;
            background: white;
            box-shadow: 0px 5px 28px 3px rgba(0,0,0,0.18);
            transition: 0.3s;
            text-decoration: none;
            @media(max-width:1024px){
                flex: 0 0 48%;
            }
            @media(max-width:768px){
                flex: 100%;
            }
            &:hover{
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.082);
                transition: 0.3s;
            }
            .coursesBoxThumbnail{
                width: 100%;
                height: 300px;
                background: $primaryColor;
                border-radius: 10px;
                overflow: hidden;
                @media(max-width:480px){
                    height: 200px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .coursesBoxContent{
                width: 100%;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                h2{
                    font-size: 22px;
                    font-weight: 500;
                    color: black;
                    @media(max-width:480px){
                        font-size: 20px;
                    }
                }
                .courseCount{
                    background: rgba(0, 89, 255, 0.096);
                    border: 1px solid $primaryColor;
                    width: 150px;
                    padding: 10px;
                    border-radius: 10px;
                    color: $primaryColor;
                    font-size: 14px;
                    text-align: center;
                    text-decoration: none;
                    font-weight: 500;
                }
            }
        }
    }
}

// services
#services{
    width: 100%;
    padding: 100px 0px;
    background: $primaryColor;
    @media(max-width:768px){
        padding: 50px 0px;
    }
    h1{
        font-size: 3em;
        font-weight: 600;
        color: white;
        text-align: center;
        @media(max-width:480px){
            font-size: 2.5em;
            line-height: 1.5;
        }
    }
    .servicesMain{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        position: relative;
        &::after{
            content: '';
            height: 0;
            width: 24%;
            @media(max-width:1024px){
                width: 32%;
            }
        }
        .servicesBox{
            flex: 0 0 24%;
            background: white;
            padding: 20px;
            border-radius: 10px;
            margin-top: 30px;
            text-decoration: none;
            transition: 0.3s;
            @media(max-width:1024px){
                flex: 0 0 32%;
            }
            @media(max-width:768px){
                flex: 0 0 48%;
            }
            @media(max-width:480px){
                flex: 100%;
            }
            &:hover{
                margin-top: 20px;
                transition: 0.3s;
            }
            h2{
                font-size: 22px;
                font-weight: 600;
                background: -webkit-linear-gradient(45deg, #7482ff, #dc52ff, #33daf1);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            p{
                font-size: 16px;
                line-height: 1.5;
                margin-top: 15px;
                opacity: 0.7;
                color: black;
                height: 95px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
        }
    }
}

// workingProcess
#workingProcess{
    width: 100%;
    padding: 100px 0px;
    background: #f8f9ff;
    @media(max-width:768px){
        padding: 50px 0px;
    }
    h1{
        font-size: 3em;
        font-weight: 600;
        text-align: center;
        @media(max-width:480px){
            font-size: 2.5em;
            text-align: left;
            line-height: 1.5;
        }
    }
    .workingProcessMain{
        width: 100%;
        display: flex;
        flex-direction: column;
        @media(max-width:768px){
            justify-content: space-between;
            flex-direction: row;
        }
        .workingProcessGraph{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 100px;
            @media(max-width:768px){
                width: 10%;
                justify-content: inherit;
                flex-direction: column;
            }
            @media(max-width:480px){
                margin-top: 50px;
            }
            .workingProcessGraphBox{
                width: 33%;
                height: 10px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                @media(max-width:768px){
                    width: 10px;
                    height: 200px;
                    margin-top: 10px;
                    display: block;
                    &:nth-child(1){
                        margin-top: 0;
                    }
                }
                &:nth-child(1){
                    background: #7482ff;
                    span{
                        background: #7482ff;
                    }
                }
                &:nth-child(2){
                    background: #dc52ff;
                    span{
                        background: #dc52ff;
                    }
                }
                &:nth-child(3){
                    background: #33daf1;
                    span{
                        background: #33daf1;
                    }
                }
                span{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: 5px solid #fafafa;
                    @media(max-width:768px){
                        display: block;
                        margin-top: calc(100px - 15px);
                        margin-left: calc(5px - 15px);
                    }
                }
            }
        }
        .workingProcessBoxMain{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            @media(max-width:768px){
                flex-direction: column;
                justify-content: flex-start;
            }
            @media(max-width:480px){
                margin-top: 0;
            }
            .workingProcessBox{
                flex: 0 0 30%;
                border-radius: 10px;
                background: white;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.041);
                padding: 15px;
                display: flex;
                align-items: center;
                @media(max-width:1024px){
                    flex: 0 0 32%;
                }
                @media(max-width:768px){
                    flex: inherit;
                    width: 100%;
                    margin-top: 120px;
                }
                @media(max-width:480px){
                    margin-top: 100px;
                    &:nth-child(2){
                        margin-top: 130px;
                    }
                    &:nth-child(3){
                        margin-top: 120px;
                    }
                }
                &:nth-child(1){
                    .workingProcessBoxThumbnail{
                        background: #7482ff28;
                        color: #7482ff;
                    }
                }
                &:nth-child(2){
                    .workingProcessBoxThumbnail{
                        background: #dc52ff27;
                        color: #dc52ff;
                    }
                }
                &:nth-child(3){
                    .workingProcessBoxThumbnail{
                        background: #33dbf127;
                        color: #33daf1;
                    }
                }
                .workingProcessBoxThumbnail{
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 25px;
                }
                h3{
                    margin-left: 20px;
                    font-size: 22px;
                    font-weight: 500;
                }
            }
        }
    }
}

// gallery
#gallery{
    width: 100%;
    padding: 100px 0px;
    @media(max-width:768px){
        padding: 50px 0px;
    }
    .galleryBox{
        width: 100%;
        height: 400px;
        border-radius: 10px;
        overflow: hidden;
        @media(max-width:480px){
            height: 200px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .owl-item{
        &:nth-child(odd) .galleryBox{
            margin-top: 100px;
        }
    }
    .owl-dot, .owl-nav{
        display: none;
    }
}

// testimonial
#testimonial{
    width: 100%;
    padding: 100px 0px;
    position: relative;
    z-index: 0;
    @media(max-width:768px){
        padding: 50px 0px;
    }
    h1{
        font-size: 3em;
        font-weight: 600;
        text-align: center;
        @media(max-width:480px){
            font-size: 2.5em;
            text-align: left;
            line-height: 1.5;
        }
    }
    .testimonialMain{
        width: 100%;
        margin-top: 100px;
        padding: 0px 25%;
        @media(max-width:1024px){
            padding: 0px;
        }
        .testimonialBox{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 15px;
            .testimonialBoxProfile{
                width: 100px;
                height: 100px;
                border-radius: 50%;
                border: 5px solid #fff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.096);
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .testimonialBoxContent{
                width: 100%;
                padding: 20px;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.048);
                background: white;
                border-radius: 20px;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                p{
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 1.7;
                    text-align: center;
                    @media(max-width:480px){
                        font-size: 18px;
                    }
                }
                h3{
                    font-size: 18px;
                    font-weight: 400;
                    text-align: center;
                    margin-top: 30px;
                }
                span{
                    font-size: 14px;
                    margin-top: 5px;
                    text-align: center;
                    opacity: 0.6;
                }
            }
        }
    }
    .owl-dot, .owl-nav{
        display: none;
    }
    .testimonialElement{
        position: absolute;
        border-radius: 50%;
        border: 3px solid white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.096);
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .testimonialElement1{
        width: 35px;
        height: 35px;
        background: white;
        left: 10%;
        top: 30%;
    }
    .testimonialElement2{
        width: 55px;
        height: 55px;
        background: white;
        left: 15%;
        top: 45%;
    }
    .testimonialElement3{
        width: 40px;
        height: 40px;
        background: white;
        left: 12%;
        bottom: 10%;
    }
    .testimonialElement4{
        width: 35px;
        height: 35px;
        background: white;
        right: 10%;
        top: 30%;
    }
    .testimonialElement5{
        width: 55px;
        height: 55px;
        background: white;
        right: 15%;
        top: 45%;
    }
    .testimonialElement6{
        width: 40px;
        height: 40px;
        background: white;
        right: 12%;
        bottom: 10%;
    }
}

// contact
#contact{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 100px;
    background: #f8f9ff;
    @media(max-width:1024px){
        margin-top: 0;
    }
    .contactThumbnail{
        width: 60%;
        background: $primaryColor;
        @media(max-width:1024px){
            width: 100%;
            height: 400px;
        }
        @media(max-width:480px){
            height: 250px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .contactForm{
        width: 40%;
        padding: 5%;
        display: flex;
        flex-direction: column;
        @media(max-width:1024px){
            width: 100%;
            padding: 100px 5%;
        }
        @media(max-width:480px){
            padding: 50px 5%;
        }
        h1{
            font-size: 3em;
            font-weight: 600;
            @media(max-width:480px){
                font-size: 2.5em;
                text-align: left;
                line-height: 1.5;
            }
        }
        p{
            font-size: 16px;
            line-height: 1.7;
            margin-top: 20px;
            opacity: 0.6;
        }
        form{
            width:100%;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            .formGroup{
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                label{
                    font-size: 16px;
                    color: $secondaryColor;
                }
                input{
                    width: 100%;
                    height: 60px;
                    border-radius: 10px;
                    border: 1px solid white;
                    outline: none;
                    background: white;
                    padding: 15px;
                    font-size: 22px;
                    font-weight: 400;
                    margin-top: 10px;
                    &:focus{
                        border: 1px solid $primaryColor;
                    }
                }
                textarea{
                    width: 100%;
                    height: 100px;
                    border-radius: 10px;
                    border: 1px solid white;
                    outline: none;
                    background: white;
                    padding: 15px;
                    font-size: 22px;
                    font-weight: 400;
                    margin-top: 10px;
                    &:focus{
                        border: 1px solid $primaryColor;
                    }
                }
            }
            button{
                width: 100%;
                padding: 17px;
                background: $primaryColor;
                color: white;
                border: none;
                outline: none;
                cursor: pointer;
                border-radius: 10px;
                margin-top: 30px;
                font-size: 18px;
                font-weight: 400;
                text-transform: uppercase;
                transition: 0.3s;
                &:hover{
                    background: $secondaryColor;
                    transition: 0.3s;
                }
            }
        }
    }
}

// footer 
footer{
    width: 100%;
    padding: 100px 0px 50px 0px;
    background: $secondaryColor;
    @media(max-width:480px){
        padding: 50px 0px;
    }
    .footerMain{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .footerBox1{
            flex: 0 0 30%;
            display: flex;
            flex-direction: column;
            @media(max-width:1024px){
                flex: 0 0 45%;
            }
            @media(max-width:768px){
                flex: 100%;
            }
            .footerLogo{
                width: 250px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            p{
                font-size: 12px;
                color: rgb(168, 168, 168);
                font-weight: 300;
                line-height: 1.7;
                margin-top: 20px;
                width: 70%;
                @media(max-width:1024px){
                    width: 100%;
                }
            }
            .footerSocialLink{
                width: 100%;
                display: flex;
                // align-items: center;
                margin-top: 30px;
                .footerSocialLinkBox{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $primaryColor;
                    color: white;
                    font-size: 16px;
                    cursor: pointer;
                    margin-right: 15px;
                    transition: 0.3s;
                    &:hover{
                        margin-top: -5px;
                        transition: 0.3s;
                    }
                }
            }
        }
        .footerBox2{
            flex: 0 0 15%;
            display: flex;
            flex-direction: column;
            @media(max-width:768px){
                flex: 0 0 50%;
                margin-top: 50px;
            }
            h2{
                font-size: 14px;
                font-weight: 400;
                color: white;
                margin-bottom: 10px;
            }
            ul{
                width: 100%;
                li{
                    list-style: none;
                    a{
                        text-decoration: none;
                        color: rgb(168, 168, 168) !important;
                        font-size: 14px;
                        margin-top: 10px;
                        display: flex;
                        font-weight: 300;
                        &:hover{
                            color: $primaryColor;
                        }
                    }
                }
            }
            
        }
        .footerBox3{
            flex: 0 0 25%;
            display: flex;
            flex-direction: column;
            @media(max-width:1024px){
                flex: 100%;
                margin-top: 50px;
            }
            h2{
                font-size: 14px;
                font-weight: 400;
                color: $primaryColor;
                margin-bottom: 10px;
                margin-top: 20px;
                &:nth-child(1){
                    margin-top: 0;
                }
            }
            a{
                text-decoration: none;
                color: white;
                font-size: 18px;
                margin-top: 5px;
                display: flex;
                line-height: 1.5;
                font-weight: 300;
                &:hover{
                    color: $primaryColor;
                }
            }
            
        }
    }
    .footerStrip{
        width: 100%;
        margin-top: 100px;
        background: rgba(0, 0, 0, 0.274);
        padding: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        @media(max-width:480px){
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
        p{
            color: rgb(168, 168, 168);
            font-size: 14px;
            font-weight: 300;
            display: flex;
            align-items: center;
            @media(max-width:480px){
                width: 100%;
                text-align: center;
                justify-content: center;
                margin-top: 10px;
                &:nth-child(1){
                    margin-top: 0px;
                }
            }
            span{
                color: red;
                font-size: 16px;
                margin: 0px 10px;
            }
            a{
                text-decoration: none;
                color: white;
            }
        }
    }
}

// aboutPageBanner
#aboutPageBanner{
    width: 100%;
    margin-top: 80px;
    padding: 100px 0px;
    @media(max-width:768px){
        margin-top: 60px;
        padding: 50px 0px;
    }
    .aboutPageBannerMain{
        width: 100%;
        position: relative;
        min-height: 75vh;
        @media(max-width:768px){
            position: inherit;
            display: flex;
            flex-direction: column;
        }
        .aboutPageBannerContent{
            position: absolute;
            width: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            left: 0;
            z-index: 1;
            @media(max-width:768px){
                width: 100%;
                position: inherit;
            }
            h1{
                font-size: 6em;
                margin-top: 100px;
                @media(max-width:768px){
                    margin-top: 0;
                    font-size: 4em;
                }
                @media(max-width:480px){
                    font-size: 2.5em;
                }
            }
        }
        .aboutPageBannerThumbnail{
            width: 65%;
            position: absolute;
            right: 0;
            z-index: 0;
            display: flex;
            flex-direction: column;
            @media(max-width:768px){
                width: 100%;
                position: inherit;
                margin-top: 50px;
            }
            .aboutPageBannerThumbnailBox{
                width: 100%;
                background: $primaryColor;
                border-radius: 10px;
                overflow: hidden;
                height: 60vh;
                @media(max-width:768px){
                    height: 400px;
                }
                @media(max-width:480px){
                    height: 250px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            p{
                font-size: 18px;
                line-height: 2;
                margin-top: 30px;
                opacity: 0.6;
                font-weight: 400;
            }
        }
    }
}

// aboutDetails
#aboutDetails{
    width: 100%;
    padding: 100px 0px;
    .aboutDetailsMain{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .aboutDetailsThumbnail{
            flex: 0 0 45%;
            border-radius: 10px;
            overflow: hidden;
            @media(max-width:768px){
                flex: 100%;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .aboutDetailContent{
            flex: 0 0 50%;
            display: flex;
            flex-direction: column;
            padding: 50px 0px;
            @media(max-width:768px){
                flex: 100%;
            }
            h2{
                font-size: 2.5em;
                font-weight: 500;
                color: $secondaryColor;
            }
            p{
                text-align: left;
                width: 100%;
                line-height: 1.7;
                font-size: 16px;
                opacity: 0.6;
                margin-top: 20px;
            }
        }
    }
    .aboutMissionVision{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px;
        @media(max-width:768px){
            margin-top: 0;
        }
        .aboutMissionVisionBox{
            flex: 0 0 45%;
            display: flex;
            flex-direction: column;
            margin-top: 50px;
            @media(max-width:768px){
                flex: 100%;
            }
            h2{
                font-size: 1.8em;
                font-weight: 500;
            }
            ul{
                width: 100%;
                display: flex;
                flex-direction: column;
                li{
                    list-style: none;
                    margin-top: 20px;
                    line-height: 1.7;
                    font-size: 16px;
                }
            }
        }
    }
}

// coursePage
.coursePage{
    margin-top: 80px !important;
    @media(max-width: 768px){
        margin-top: 60px !important;
    }
}

// testimonialPage
#testimonialPage{
    width: 100%;
    margin-top: 80px;
    padding: 100px 20%;
    @media(max-width:768px){
        padding: 50px 5%;
    }
    .testimonialPageBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 150px;
        &:nth-child(1){
            margin-top: 0;
        }
        .testimonialPageBoxProfile{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 5px solid #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.096);
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .testimonialPageBoxContent{
            width: 100%;
            padding: 20px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.048);
            background: white;
            border-radius: 20px;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p{
                font-size: 20px;
                font-weight: 300;
                line-height: 1.7;
                text-align: center;
                @media(max-width:480px){
                    font-size: 18px;
                }
            }
            h3{
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                margin-top: 30px;
            }
            span{
                font-size: 14px;
                margin-top: 5px;
                text-align: center;
                opacity: 0.6;
            }
        }
    }
}

// galleryPage
#galleryPage{
    width: 100%;
    margin-top: 80px;
    padding: 100px 0px;
    @media(max-width:768px){
        margin-top: 60px;
        padding: 50px 0px;
    }
    h1{
        font-size: 3em;
        font-weight: 600;
        text-align: center;
        @media(max-width:480px){
            font-size: 2.5em;
            text-align: left;
            line-height: 1.5;
        }
    }
    .galleryPageMain{
        width: 100%;
        margin-top: 100px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        @media(max-width:480px){
            margin-top: 30px;
        }
        &::after{
            content: '';
            height: 0;
            width: 24%;
        }
        img{
            flex: 0 0 24%;
            border-radius: 10px;
            overflow: hidden;
            height: 300px;
            display: flex;
            margin-top: 20px !important; 
            cursor: pointer;
            object-fit: cover;
            @media(max-width:1024px){
                flex: 0 0 30%;
            }
            @media(max-width:768px){
                flex: 0 0 48%;
            }
            @media(max-width:480px){
                height: 150px;
                margin-top: 20px;
            }
        }
        .dummyGalleryPageBox{
            flex: 0 0 24%;
            height: 0;
            @media(max-width:1024px){
                flex: 0 0 30%;
            }
        }
    }
    .styles-module_wrapper__1I_qj{
        z-index: 200 !important;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            flex: inherit;
        }
    }
}

// contactPage
#contactPage{
    width: 100%;
    margin-top: 80px;
    padding: 100px 0px;
    @media(max-width:768px){
        padding: 50px 0px;
        margin-top: 60px;
    }
    h1{
        font-size: 3em;
        font-weight: 600;
        text-align: center;
        @media(max-width:480px){
            font-size: 2.5em;
            line-height: 1.5;
        }
    }
    p{
        font-size: 20px;
        font-weight: 300;
        margin-top: 30px;
        text-align: center;
        opacity: 0.7;
        line-height: 1.7;
        @media(max-width:480px){
            font-size: 18px;
            margin-top: 20px;
        }
    }
    .contactPageMain{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 50px;
        .contactPageBox{
            flex: 0 0 30%;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 50px;
            @media(max-width:1024px){
                flex: 100%;
            }
            @media(max-width:480px){
                margin-top: 60px;
            }
            span{
                font-size: 30px;
                color: $primaryColor;
            }
            h3{
                font-size: 16px;
                font-weight: 500;
                margin-top: 10px;
                text-align: center;
                opacity: 0.6;
            }
            a{
                text-align: center;
                text-decoration: none;
                color: $secondaryColor;
                font-size: 25px;
                margin-top: 30px;
                line-height: 1.7;
                @media(max-width:1440px){
                    font-size: 22px;
                }
                @media(max-width:480px){
                    margin-top: 20px;
                }
                &:hover{
                    color: $primaryColor;
                }
            }
        }
    }
}

// innerCourse
#innerCourseBanner{
    width: 100%;
    padding: 100px 0px;
    margin-top: 80px;
    background: $primaryColor;
    background-image: url(../../assets/images/innerServiceShape.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media(max-width:768px){
        padding: 50px 0px;
        margin-top: 60px;
    }
    h1{
        font-size: 3.5em;
        font-weight: 500;
        color: white;
        @media(max-width:768px){
            font-size: 2.5em;
        }
        @media(max-width:480px){
            font-size: 2.5em;
            line-height: 1.5;
        }
    }
}
// .UgBanner{
//     background: $primaryColor;
//     background-image: url(../../assets/images/innerServiceShape.svg);
// }
// .PgBanner{
//     background: rgb(161, 0, 224);
//     background-image: url(../../assets/images/innerServiceShape2.svg);
// }

// innerCourseList
#innerCourseList{
    width: 100%;
    padding: 80px 0px 100px 0px;
    background: rgba(246, 248, 248, 0.856);
    @media(max-width:480px){
        padding: 50px 0px;
    }
    }
    .innerCourseListFilter{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        display: none;
        .innerCourseListFilterBox{
            background: white;
            padding: 12px 25px;
            border-radius: 10px;
            font-size: 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            transition: 0.2s;
            &:hover{
                background: rgb(251, 251, 251);
                transition: 0.2s;
            }
            i{
                margin-right: 5px;
                font-size: 20px;
                display: flex;
                align-items: center;
            }
            p{
                color: rgb(97, 97, 97);
            }
        }
    }
    .innerCourseTab{
        width: 400px;
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: 110;
        background: white;
        top: 80px;
        right: -110%;
        padding-bottom: 10px;
        overflow-Y: auto;
        height: calc(100vh - 80px);
        transition: 0.3s all ease-in-out;
        &::-webkit-scrollbar {
            width: 5px;
        }
        .innerCourseTabBox{
            width: 100%;
            padding: 10px 20px;
            background: white;
            border-radius: 10px;
            font-size: 14px;
            margin-top: 20px;
            input[type="radio"]{
                display: none;
            }
            input[type="radio"] + label{
                position: relative;
                display: inline-block;
                padding-left: 30px;
                cursor: pointer;
                -webkit-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
            }
            input[type="radio"] + label:before,
            input[type="radio"] + label:after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 15px;
                height: 15px;
                text-align: center;
                color: white;
                border-radius: 50%;
                -webkit-transition: all .3s ease;
                transition: all .3s ease;
            }
            input[type="radio"] + label:before {
                -webkit-transition: all .3s ease;
                transition: all .3s ease;
                box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
                border: 1.5px solid $primaryColor;
            }
            input[type="radio"] + label:hover:before {
                -webkit-transition: all .3s ease;
                transition: all .3s ease;
                box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em $primaryColor;
            }
            input[type="radio"]:checked + label:before {
                -webkit-transition: all .3s ease;
                transition: all .3s ease;
                box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em $primaryColor;
            }
        }
    }
    .innerCourseTabActive{
        right: 0;
        transition: 0.3s all ease-in-out;
    }
    .innerCourseListMain{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        .innerCourseListBox{
            background: white;
            width: 100%;
            margin-top: 50px;
            border-radius: 10px;
            padding: 20px;
            display: flex;
            text-decoration: none;
            flex-direction: column;
            &:nth-child(1){
                margin-top: 0;
            }
            .innerCourseListBoxHead{
                width: 100%;
                display: flex;
                padding-bottom: 20px;
                border-bottom: 1px solid #eee;
                h1{
                    font-size: 2.3em;
                    color: black;
                    font-weight: 500;
                    @media(max-width:1024px){
                        font-size: 2em;
                    }
                    @media(max-width:768px){
                        font-size: 30px;
                    }
                }
            }
            .innerCourseListBoxBody{
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-top: 20px;
                span{
                    font-size: 20px;
                    color: $primaryColor;
                    margin-top: 30px;
                    &:nth-child(1){
                        margin-top: 0;
                    }
                }
                p{
                    font-size: 18px;
                    margin-top: 15px;
                    opacity: 0.8;
                    color: black;
                }
                .innerCourseListBoxBodyDuration{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    .innerCourseListBoxBodyDurationBox{
                        width: calc(100% / 3);
                        display: flex;
                        flex-direction: column;
                        border: 1px solid #eee;
                        @media(max-width:1024px){
                            width: calc(100% / 2);
                        }
                        @media(max-width:768px){
                            width: 100%;
                        }
                        ul{
                            width: 100%;
                            li{
                                list-style: none;
                                font-size: 18px;
                                color: black;
                                line-height: 1.7;
                                padding: 10px 20px;
                                &:nth-child(1){
                                    border-bottom: 1px solid #eee;
                                    padding: 15px 20px;
                                    font-size: 22px;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
                .innerCourseContact{
                    text-decoration: none;
                    margin-top: 40px;
                    padding: 15px 30px;
                    background: $primaryColor;
                    color: white;
                    font-size: 16px;
                    width: 200px;
                    text-align: center;
                    border-radius: 10px;
                    transition: 0.3s;
                    &:hover{
                        background: $secondaryColor;
                        transition: 0.3s;
                    }
                }
            }
        }
    }


// servicesPage.
#servicesPage{
    width: 100%;
    margin-top: 80px;
    padding: 100px 0px;
    @media(max-width:768px){
        padding: 50px 0px;
        margin-top: 60px;
    }
    h1{
        font-size: 25px;
        width: 50%;
        line-height: 1.5;
        font-weight: 500;
        @media(max-width:1024px){
            width: 100%;
        }
    }
    .servicesPageMain{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 100px;
        .servicesPageThumbnail{
            flex: 0 0 30%;
            min-height: 600px;
            background: black;
            border-radius: 10px;
            overflow: hidden;
            @media(max-width:1024px){
                flex: 0 0 45%;
            }
            @media(max-width:768px){
                flex: 100%;
                height: 350px;
                min-height: inherit;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .servicesPageContent{
            flex: 0 0 62%;
            display: flex;
            flex-direction: column;
            @media(max-width:1024px){
                flex: 0 0 50%;
            }
            @media(max-width:768px){
                flex: 100%;
                margin-top: 30px;
            }
            h2{
                font-size: 3em;
                font-weight: 600;
                background: -webkit-linear-gradient(45deg, #7482ff, #dc52ff, #33daf1);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @media(max-width:480px){
                    font-size: 2em;
                }
            }
            p{
                font-size: 18px;
                line-height: 1.7;
                opacity: 0.7;
                margin-top: 20px;
                color: rgb(0, 2, 37);
                @media(max-width:480px){
                    font-size: 16px;
                }
            }
            .servicesPageContentBtns{
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 40px;
                @media(max-width:480px){
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                }
                .viewBtn{
                    padding: 15px 30px;
                    border-radius: 10px;
                    background: #e9ebff;
                    color: #7482ff;
                    text-decoration: none;
                    transition: 0.3s;
                    margin-right: 20px;
                    &:hover{
                        background: #7482ff;
                        color: white;
                        transition: 0.3s;
                    }
                    @media(max-width:480px){
                        margin-right: 0;
                        margin-top: 10px;
                    }
                }
                .erpBtn{
                    padding: 15px 30px;
                    border-radius: 10px;
                    background: #be6afc;
                    color: #fff;
                    text-decoration: none;
                    transition: 0.3s;
                    margin-right: 20px;
                    &:hover{
                        background: #e9ebff;
                        color: #7482ff;
                        transition: 0.3s;
                    }
                    @media(max-width:480px){
                        margin-left: 0;
                        margin-top: 10px;
                    }
                }
                
                .ContactBtn{
                    padding: 15px 30px;
                    border-radius: 10px;
                    background: $primaryColor;
                    color: #fff;
                    text-decoration: none;
                    margin-right: 20px;
                    transition: 0.3s;
                    &:hover{
                        background: #e9ebff;
                        color: #7482ff;
                        transition: 0.3s;
                    }
                    @media(max-width:480px){
                        margin-left: 0;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}