@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  font-family: 'Rubik', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #002147;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7482ff;
}

.container {
  width: 90%;
  margin: 0 auto;
}

a, button, div, span, i, b, h1, h2, h3, h4, h5, h6, p, input, ul, li, select, label, textarea {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Rubik', sans-serif;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

input {
  -webkit-tap-highlight-color: transparent;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

textarea {
  resize: none;
  font-family: 'Rubik', sans-serif;
}

main {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

nav {
  width: 100%;
  height: 80px;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  -webkit-box-shadow: 0 0 15px rgba(5, 4, 4, 0.11);
          box-shadow: 0 0 15px rgba(5, 4, 4, 0.11);
}

@media (max-width: 768px) {
  nav {
    height: 60px;
  }
}

nav .navLogo {
  height: 80px;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  nav .navLogo {
    height: 60px;
  }
}

nav .navLogo img {
  width: 75%;
  height: 75%;
  -o-object-fit: contain;
     object-fit: contain;
}

@media (max-width: 480px) {
  nav .navLogo img {
    height: 75%;
    width: 75%;
  }
}

nav .navLink {
  float: right;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1024px) {
  nav .navLink {
    display: none;
  }
}

nav .navLink ul {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

nav .navLink ul li {
  list-style: none;
  float: left;
}

nav .navLink ul li a {
  text-decoration: none;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 50px;
  color: #6e6d6d;
}

nav .navLink ul li a:hover {
  color: #7482ff;
}

nav .navLink ul li .navLinkActive {
  color: #7482ff;
  font-weight: 500;
}

nav .navLink ul li .navContact {
  background: #7482ff;
  color: white;
  padding: 12px 35px;
  border-radius: 15px;
  font-weight: 300;
  margin-left: 80px;
}

nav .navLink ul li .navContact:hover {
  background: #002147;
}

nav .navBar {
  display: none;
  height: 80px;
  float: right;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1024px) {
  nav .navBar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (max-width: 768px) {
  nav .navBar {
    height: 60px;
  }
}

nav .navBar .navBarBox {
  cursor: pointer;
  width: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

nav .navBar .navBarBox span {
  width: 100%;
  height: 2px;
  background: #002147;
  margin-top: 3px;
}

nav .navBar .navBarBox span:nth-child(1) {
  margin-top: 0;
}

.sidemenu {
  background-color: white;
  position: fixed;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  top: 0;
  right: -100%;
  z-index: 110;
  height: 100vh;
  width: 100%;
  display: block;
}

.sidemenu .sidemenuClose {
  float: right;
  padding: 20px;
}

.sidemenu .sidemenuClose i {
  font-size: 30px;
  color: #002147;
}

.sidemenu .burgerMenu {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 50px;
}

.sidemenu .burgerMenu li {
  list-style: none;
  padding: 10px 0;
}

.sidemenu .burgerMenu li a {
  text-decoration: none;
  padding: 20px;
  color: black;
  font-size: 18px;
}

.sidemenu .burgerMenu li a:hover {
  color: #7482ff;
}

.sidemenuActive {
  right: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
