
// font 
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

// color 
$primaryColor: #7482ff; 
$secondaryColor: #002147;



body{
    font-family: 'Rubik', sans-serif;
}
/* width */
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $secondaryColor; 
    border-radius: 5px;
    &:hover{
        background: $primaryColor; 
    }
  }


.container{
    width: 90%;
    margin: 0 auto;
}

a, button, div, span, i, b, h1, h2, h3, h4, h5, h6, p, input, ul, li, select, label, textarea {
    -webkit-touch-callout: none;
    // -webkit-user-select: none;
    // -khtml-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Rubik', sans-serif;
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    font-family: 'Rubik', sans-serif;
}
input{
    -webkit-tap-highlight-color: transparent; 
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
}
textarea{
    resize: none;
    font-family: 'Rubik', sans-serif;
}

main{
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
